import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import OurTeam from '../components/UI/Images/OurTeam/OurTeam'
import './../styles/pageSpecific/ourTeam.scss'

const OurTeamPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Our',
    title2: 'Leadership Team',
    breadcrumbs: ['Who We Are'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id="ourTeam"
          color="white"
          type="contained"
          classes={['inlineChildren_mobile']}
        >
          <OurTeam />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default OurTeamPage
