import React, {Fragment, useRef, createRef } from "react"
import { graphql, useStaticQuery  } from 'gatsby'
import * as styles from './OurTeam.module.scss'
import OurTeamList from './../../../../data/team.json'
import TeamMember from "./TeamMember/TeamMember"

const OurTeam = () => {
    const refArray = useRef([]);

    const { allFile  } = useStaticQuery(graphql`
        query {
            allFile(filter: {
                extension: {regex: "/(jpg)|(jpeg)|(png)/"}, 
                sourceInstanceName: {eq: "team"}}) 
            {
                edges {
                    node {
                        name
                        childImageSharp {
                            gatsbyImageData(
                                width: 350
                                placeholder: BLURRED
                                layout:CONSTRAINED
                                )
                            }
                        }
                    }
        }}`);

        if (refArray.current.length !== OurTeamList.length) {
            refArray.current = Array(OurTeamList.length).fill().map((_, i) => refArray.current[i] || createRef());
          }

    const team = OurTeamList.map((member, index) => {
        let teamImg = allFile.edges.find(person => person.node.name === member.img)

        return <TeamMember 
                key={member.name} 
                teamMember={member} 
                teamImg={teamImg} 
                ref={refArray.current[index]}/>
    })

        let key = 0
        while(team.length % 5 !== 0){
          key++
          team.push(<section key={key} className={styles.placeholderTeamMember}></section>)
        }
 
    return(  
        <Fragment>
             { team }           
        </Fragment>      
    )
}

export default OurTeam